import {Controller} from 'stimulus';
import {popSnackbar} from "../services/snackbar";

export default class extends Controller {
  // Note: Collection of generic JS actions such and setting value on click, popping snackbar

  setValue(event) {
    let data = event.currentTarget.dataset
    let object = JSON.parse(data.object)
    if (document.getElementById(data.displayId)) {
      document.getElementById(data.displayId).value = object.display
    }
    if (document.getElementById(data.valueId)) {
      document.getElementById(data.valueId).value = object.value
    }
  }

  popMessage(event) {
    let data = event.currentTarget.dataset
    popSnackbar(data.messageType, data.message)
  }
}
