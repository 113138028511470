import {Controller} from 'stimulus';
import {popSnackbar} from '../services/snackbar';
import {I18n} from '../translation';
import {cartPriceAndItemReplaceDom} from '../services/carts/replace_dom';
import {SharedService} from '../services/shared_service';


export default class extends Controller {
  appliedCouponId = [];

  connect() {
    if(document.getElementById('gift-card-code')){
      document.getElementById('gift-card-code').addEventListener('input', (event) => {
        if(event.currentTarget.value.length === 16) {
          new SharedService().debounceAction(this.check_gift_code(event), 300);
        } else {
          document.getElementById('gift_card_code_error_message').classList.add('hidden');
        }
      });
    }
  }

  async applyGiftCard(event) {
    event.preventDefault();
    const giftCode = document.getElementById('gift-card-code').value;
    let membership = JSON.parse(sessionStorage.getItem('purchaseMembershipOn')) === 'true';
    if(giftCode){
      const url = '/gift_cards/apply_gift_card';
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
        },
        body: JSON.stringify({code: giftCode, membership: membership})
      });

      if(response.status === 200){
        let text = await response.json();
        cartPriceAndItemReplaceDom(text);
        // eslint-disable-next-line no-undef
        Turbo.visit('/carts', { target: 'payment-means-details' });
        document.getElementById('applied-gift-card-content').innerHTML = text.gift_card;
        popSnackbar('notice', I18n[window.currentLocale]['gift_card_applied_successfully']);
      }
    }
  }

  async remove() {
    let membership = `JSON.parse(sessionStorage.getItem('purchaseMembershipOn')) === 'true'`;
    const url = '/gift_cards/apply_gift_card';
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      },
      body: JSON.stringify({code: null, membership: membership})
    });

    if (response.status === 200) {
      let text = await response.json();
      cartPriceAndItemReplaceDom(text);
      // eslint-disable-next-line no-undef
      Turbo.visit('/carts', {target: 'payment-means-details'});
      document.getElementById('applied-gift-card-content').innerHTML = text.gift_card;
      popSnackbar('notice', I18n[window.currentLocale]['gift_card_removed_successfully']);
    }
  }

  async check_gift_code(event) {
    event.preventDefault();
    const giftCode = document.getElementById('gift-card-code').value;
    const url = `/gift_cards/check_gift_card?code=${giftCode}`;
    const response = await fetch(url);

    if (response.status === 200) {
      let json = await response.json();
      if (json.available) {
        document.getElementById('apply_gift_card_button').disabled = false;
        document.getElementById('gift_card_code_error_message').classList.add('hidden');
      } else {
        document.getElementById('apply_gift_card_button').disabled = true;
        document.getElementById('gift_card_code_error_message').classList.remove('hidden');
      }
    }
  }
  changeIcon() {
    const content = document.getElementById('minus-icon');
    content.innerHTML === 'add' ? content.innerHTML = 'remove' : content.innerHTML = 'add';
  }
}
