import {Controller} from 'stimulus';

export default class extends Controller {
  connect() {
    let items = JSON.parse(this.element.dataset.items);
    // eslint-disable-next-line no-undef
    setTimeout(() => {
      dataLayer.push({ecommerce: null});
      dataLayer.push(
        {
          event: "view_cart",
          ecommerce: {
            currency: 'CHF',
            value: +document.getElementById('total_amount').innerText.split(' ')[1],
            items: items
          }
        }
      );
    }, 3000);
  }
}
