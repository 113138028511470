import {popSnackbar} from '../snackbar';
import {I18n} from '../../translation';

export const createTaxonomy = function (data) {
  fetch('/admin/categories', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
    },
    body: JSON.stringify(data)
  })
    .then((response) => response.text())
    .then((data) => {
      let vDom = document.createElement('div');
      vDom.innerHTML = data;

      document.getElementById('taxonomies').replaceWith(vDom.children[0]);
      document.getElementById('cancel-sub-category-createBtn').click();
      popSnackbar('notice', I18n[window.currentLocale]['product_type_creation_notice']);
    })
    .catch((error) => {
      console.error('Error:', error);
    });
};

export const editTaxonomy = function (data, id) {
  fetch(`/admin/categories/${id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
    },
    body: JSON.stringify(data)
  })
    .then((response) => response.text())
    .then((data) => {
      let vDom = document.createElement('div');
      vDom.innerHTML = data;

      document.getElementById('taxonomies').replaceWith(vDom.children[0]);

      popSnackbar('notice', I18n[window.currentLocale]['product_type_edition_notice']);
    })
    .catch((error) => {
      console.error('Error:', error);
    });
};

export const editTaxonomyLabel = function (data, id) {
  fetch(`/admin/categories/${id}/update_label`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
    },
    body: JSON.stringify(data)
  })
    .then((response) => response.json())
    .then((data) => {
      popSnackbar('notice', data.message);
    })
    .catch((error) => {
      console.error('Error:', error);
    });
};

export const editTaxon = function (data, id, entity_type) {
  data['entity_type'] = entity_type;
  fetch(`/admin/taxons/${id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
    },
    body: JSON.stringify(data)
  })
    .then((response) => response.text())
    .then((data) => {
      let vDom = document.createElement('div');
      vDom.innerHTML = data;

      document.getElementById('taxonomies').replaceWith(vDom.children[0]);

      if (document.getElementById('add_category'))
        popSnackbar('notice', I18n[window.currentLocale]['category_edition_notice']);
      else
        popSnackbar('notice', I18n[window.currentLocale]['subcategory_edition_notice']);

    })
    .catch((error) => {
      console.error('Error:', error);
    });
};

export const createTaxons = function (data) {
  fetch('/admin/taxons', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
    },
    body: JSON.stringify(data)
  })
    .then((response) => response.text())
    .then((data) => {
      let vDom = document.createElement('div');
      vDom.innerHTML = data;

      document.getElementById('taxonomies').replaceWith(vDom.children[0]);
      document.getElementById('cancel-sub-category-createBtn').click();

      if (document.getElementById('add_category'))
        popSnackbar('notice', I18n[window.currentLocale]['category_creation_notice']);
      else
        popSnackbar('notice', I18n[window.currentLocale]['sub_category_creation_notice']);
    })
    .catch((error) => {
      console.error('Error:', error);
    });
};

export const touchTaxonomy = function (path, method) {
  fetch(path, {
    method: method,
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
    }
  })
    .then((response) => response.text())
    .then((data) => {
      let vDom = document.createElement('div');
      vDom.innerHTML = data;
      document.getElementById('taxonomies').replaceWith(vDom.children[0]);
      popSnackbar('notice', 'Operation Success.');
    })
    .catch((error) => {
      console.error('Error:', error);
    });
};
