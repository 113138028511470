import {Controller} from 'stimulus';

export default class extends Controller {
  connect() {
    // eslint-disable-next-line no-undef
    dataLayer.push({ecommerce: null});
    dataLayer.push(
      {
        event: "purchase",
        ecommerce: JSON.parse(this.element.dataset.gtmInfo)
      }
    );
  }
}
