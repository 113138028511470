import {Controller} from 'stimulus';
import {freeFieldValidation} from '../services/authentications/free_field_service';
import {phoneValidation} from '../services/authentications/phone_service';
import {AsYouType} from 'libphonenumber-js';
import {countryCode} from '../services/authentications/country-code_service';

export default class extends Controller {
  valueEditValidators = {
    'edit_first_name': false,
    'edit_last_name': false,
    'edit_phone': false,
    'edit_email': false
  };

  fieldValues = {
    'edit_first_name': '',
    'edit_last_name': '',
    'edit_phone': '',
    'edit_email': ''
  };

  connect() {
    let code = countryCode('Switzerland');
    let functionMap = {
      'edit_first_name': ValidateName,
      'edit_last_name': ValidateName,
      'edit_phone': ValidatePhone,
    };

    let inputs = document.querySelectorAll('.edit_profile');

    this.saveValueReference(inputs);

    for (let i = 0; i < inputs.length; i++) {
      inputs[i].addEventListener('input', function (event) {
        functionMap[event.target.id](event.target, i);
        submitButton();
      });
    }

    let _this = this;

    function ValidateName(input) {
      let freeFieldStatus = freeFieldValidation(input, input.id + '_error_message');
      if (freeFieldStatus) {
        _this.valueEditValidators[input.id] = input.value !== _this.fieldValues[input.id];
      }
    }

    function ValidatePhone(input) {
      input.value = new AsYouType(code['short_name']).input(input.value);

      let phoneStatus = phoneValidation(input, code['short_name']);
      if (phoneStatus) {
        _this.valueEditValidators[input.id] = input.value !== _this.fieldValues[input.id];
      }
    }

    function submitButton() {
      let keys = Object.keys(_this.valueEditValidators);
      let values = keys.map(function(val) { return _this.valueEditValidators[val]; });

      document.getElementById('admin_profile_edit_submit').disabled = !values.includes(true);
    }
  }

  saveValueReference(inputs) {
    inputs.forEach(field => {
      this.fieldValues[field.id] = field.value;
    });
  }
}