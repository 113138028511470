import {Controller} from 'stimulus';
import {UiService} from '../services/ui_service';

export default class extends Controller {
  changeIcon() {
    const content = document.getElementById('product-detail-icon');
    content.innerHTML === 'remove' ? content.innerHTML = 'add' : content.innerHTML = 'remove';
  }

  checkAndInitDisplayToggle() {
    const content = document.getElementById('truncated-text');
    if (content && content.innerText.length > 100) {
      document.getElementById('show-more').hidden = false;
      new UiService().initDisplayToggleButton();
    } else {
      document.getElementById('show-more').hidden = true;
    }
  }
}
