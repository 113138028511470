import {Controller} from 'stimulus';
import Quill from 'quill';

export default class extends Controller {
  quill;

  connect() {
    const editorContainer = this.element.querySelector('#product_description_editor');

    this.quill = new Quill(editorContainer,
      (this.element.dataset.readOnly ? this.quillReadonlyConfig : this.quillDefaultConfig)
    );

    if (!this.element.dataset.readOnly) this.saveOnTextChange(editorContainer);
    if (window.location.pathname.includes('new')) {
      this.saveOnPreviewTextChange(editorContainer);
    }
    if (location.href.includes('edit') || this.element.dataset.readOnly) {
      this.saveOnPreviewTextChange(editorContainer);
      this.patchRichText(this.element.dataset.productDescription);
    }
  }

  saveOnTextChange(editorContainer) {
    this.quill.on('text-change', () => {
      const element = Quill.find(editorContainer).container;
      const targetId = element.dataset.targetFieldId;
      document.getElementById(targetId).value = element.children[0].innerHTML;
    });
  }

  saveOnPreviewTextChange(editorContainer) {
    this.quill.on('text-change', () => {
      const element = Quill.find(editorContainer).container;
      document.getElementById('product_description_editor').innerHTML = element.children[0].innerHTML;
    });
  }

  patchRichText(description) {
    const productDescription = description || this.element.closest('form').dataset.description;
    const delta = this.quill.clipboard.convert(productDescription);
    this.quill.setContents(delta.ops);
  }

  get quillDefaultConfig() {
    let toolbarOptions = [
      [{size: ['small', false, 'large', 'huge']}],
      ['bold', 'italic', 'underline', 'link', 'video', 'image'],
      [{'list': 'ordered'}, {'list': 'bullet'}],
      ['clean']
    ];
    return {
      modules: {
        toolbar: toolbarOptions
      },
      theme: 'snow',
    };
  }

  get quillReadonlyConfig() {
    return {
      theme: 'snow',
      modules: {
        toolbar: false
      },
      readOnly: true
    };
  }
}
