import {Controller} from 'stimulus';
import {popSnackbar} from '../services/snackbar';
import {newSubCategoryField} from '../services/templates/products/categories/new-sub-category-field';
import {createTaxons} from '../services/templates/api';
import {I18n} from '../translation';
import {LOCALES} from '../constants/constants';

export default class extends Controller {

  static targets = ['subCategoryDom', 'newSubCategoryDom'];

  formData = {
    subCategoryNames: {
      en: [],
      de: [],
      fr: []
    }
  };

  subCategoryCounter = 0;
  localeChips = document.getElementById('addSubCategory').querySelectorAll('[class*="locale-chip"]');
  currentLocale = 'en';
  taxonomyID;

  connect() {
    this.taxonomyID = this.element.dataset.taxonomyId;
    this.showAddCategory();
  }

  addSubCategory() {
    this.subCategoryCounter++;

    if (this.subCategoryPresent()) {
      this.subCategoryDomTarget.classList.remove('hidden');
      let tmp = document.createElement('div');
      tmp.innerHTML = newSubCategoryField(this.subCategoryCounter, 'add-sub-category');

      this.newSubCategoryDomTarget.appendChild(tmp.children[0]);
    }
    document.getElementById('saveSubCategory').setAttribute('disabled', 'true');
  }

  removeSubCategory(event) {
    let index = event.currentTarget.dataset.index;

    document.getElementById(`sc_dom_${index}`).remove();

    // remove from the subcategories
    this.formData.subCategoryNames[this.currentLocale].splice(index - 1, 1);

    this.subCategoryCounter--;

    if (!this.subCategoryPresent()) {
      this.subCategoryDomTarget.classList.add('hidden');
    }
    this.checkSubCategoryInput();
  }

  subCategoryPresent() {
    return this.subCategoryCounter > 0;
  }

  switchLocale(event) {
    this.currentLocale = event.currentTarget.dataset.locale;

    document.querySelectorAll('.sc-fields').forEach(field => {
      field.value = this.formData.subCategoryNames[this.currentLocale][+field.dataset.index - 1]?.name || '';
    }, this);

    let targetLocale = event.currentTarget.dataset.locale;

    this.localeChips.forEach(chip => {
      if (chip.dataset.locale !== targetLocale) {
        chip.classList.remove('sk-language-active', 'active-locale');
        chip.classList.add('sk-language-inactive');
      }
    });

    event.currentTarget.classList.add('sk-language-active', 'active-locale');
  }

  enableSubmission(valid) {
    (LOCALES.every(locale => (this.formData.subCategoryNames[locale] || []).length) && valid)?
      document.getElementById('saveSubCategory').removeAttribute('disabled')
      : document.getElementById('saveSubCategory').setAttribute('disabled', 'true');
  }

  subCategoryInput(event) {
    let index = +event.target.dataset.index - 1;
    let data = {name: '', index: index};
    data.name = event.target.value;
    this.formData.subCategoryNames[this.currentLocale][index] = data;
    this.checkSubCategoryInput();
  }

  checkSubCategoryInput() {
    let flag = false;
    let _this = this;
    Object.keys(this.formData.subCategoryNames).forEach(keys => {
      if (_this.formData.subCategoryNames[keys].length !== _this.subCategoryCounter) {
        flag = true;
      } else {
        _this.formData.subCategoryNames[keys].forEach(function (x) {
          if (x.name.length <= 0) {
            flag = true;
          }
        });
      }
    });
    this.enableSubmission(!flag);
  }

  duplicateAll() {
    this.copySubCategoryNames();

    // validate if duplicate successful
    popSnackbar('notice', I18n[window.currentLocale]['content_duplication_success_notice']);
    this.checkSubCategoryInput();
  }

  copySubCategoryNames() {
    Object.keys(this.formData.subCategoryNames).forEach(locale => {
      if (locale !== this.currentLocale) {
        this.formData.subCategoryNames[locale] = this.formData.subCategoryNames[this.currentLocale].map(subCategory => ({...subCategory}));
      }
    }, this);
  }

  saveSubCategory(event) {
    let data = [this.formData];
    data.push({taxonomy_id: this.taxonomyID, entity_type: event.currentTarget.dataset.entityType });

    createTaxons(data);
    this.resetFormData();
  }

  cancelAndResetModal() {
    this.resetFormData();
    this.enableSubmission(false);
    this.removeAllSubcategories();
  }

  showAddCategory() {
    const tabs = document.querySelectorAll('#tabs-tab .nav-link');
    const addCategoryBtn = document.getElementById('add_category');
    if (tabs[0].classList.contains('active')) {
      addCategoryBtn.classList.remove('hidden');
    }

    tabs.forEach(tab => {
      tab.addEventListener('click', (e) => {
        if (e.target.getAttribute('data-bs-target') !== '#tabs-category') {
          addCategoryBtn.classList.add('hidden');
        } else {
          addCategoryBtn.classList.remove('hidden');
        }
      });
    });

  }

  removeAllSubcategories() {
    const subCategories = document.querySelectorAll('[id^=\'sc_dom_\']');
    subCategories.forEach(categoryDom => categoryDom.remove());
    this.subCategoryCounter = 0;
    this.subCategoryDomTarget.classList.add('hidden');
  }

  resetFormData() {
    this.formData = {
      subCategoryNames: {
        en: [],
        de: [],
        fr: []
      }
    };
  }
}
