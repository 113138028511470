import {Controller} from 'stimulus';
import {popSnackbar} from '../services/snackbar';
import {freeFieldValidation} from '../services/authentications/free_field_service';
import {editTaxonomyLabel} from '../services/templates/api';
import {I18n} from '../translation';

export default class extends Controller {
  LOCALES = ['de', 'en', 'fr'];
  saveCount = false;
  initialCategoryLabel = {
    en: '',
    fr: '',
    de: ''
  };
  initialTopSellerLabel = {
    en: '',
    fr: '',
    de: ''
  };
  formValidation = {
    validCategoryLabel: {
      en: true,
      fr: true,
      de: true
    },
    validTopSellerLabel: {
      en: true,
      fr: true,
      de: true
    }
  };

  formData = {
    editedCategoryLabel: {
      en: '',
      fr: '',
      de: ''
    },
    editedTopSellerLabel: {
      en: '',
      fr: '',
      de: ''
    }
  };


  localeChips = document.querySelectorAll('.locale-chips');
  currentLocale = 'en';

  categoryLabelInput(event) {
    this.saveInitialData(event);
    this.formValidation.validCategoryLabel[this.currentLocale] = freeFieldValidation(event.target, `${event.target.id}_error_message`);
    this.formData.editedCategoryLabel[this.currentLocale] = event.target.value;
    this.submissionCtrl();
  }

  topSellerLabelInput(event) {
    this.saveInitialData(event);
    this.formValidation.validTopSellerLabel[this.currentLocale] = freeFieldValidation(event.target, `${event.target.id}_error_message`);
    this.formData.editedTopSellerLabel[this.currentLocale] = event.target.value;
    this.submissionCtrl();
  }

  switchLocale(event) {
    this.saveInitialData(event);
    this.currentLocale = event.currentTarget.dataset.locale;

    let categoryLabel = this.formData.editedCategoryLabel[this.currentLocale];
    let topSellerLabel = this.formData.editedTopSellerLabel[this.currentLocale];
    document.getElementById('category_label_name').value = categoryLabel || '';
    document.getElementById('top_seller_label_name').value = topSellerLabel || '';

    this.localeChips = document.getElementById(this.modalID).querySelectorAll('[class*=locale-chips]');
    this.localeChips.forEach(chip => {
      if (chip.dataset.locale !== event.currentTarget.dataset.locale) {
        chip.classList.remove('sk-language-active', 'active-locale');
        chip.classList.add('sk-language-inactive');
      }
    });

    event.currentTarget.classList.add('sk-language-active', 'active-locale');
  }

  saveInitialData(event) {
    if (!this.saveCount) {
      let value = JSON.parse(event.currentTarget.dataset.object);
      this.LOCALES.forEach(locale => {
        this.initialCategoryLabel[locale] = value.label_one_text[locale] || '';
        this.formData.editedCategoryLabel[locale] = value.label_one_text[locale] || '';
        this.initialTopSellerLabel[locale] = value.label_two_text[locale] || '';
        this.formData.editedTopSellerLabel[locale] = value.label_two_text[locale] || '';
      });
      this.modalID = event.currentTarget.dataset.modalid;
      this.saveCount = true;
    }
  }

  submissionCtrl() {
    let validCount = 0;
    let editedCount = 0;
    Object.keys(this.formValidation.validCategoryLabel || this.formValidation.validTopSellerLabel).forEach(locale => {
      if (this.formValidation.validCategoryLabel[locale] || this.formValidation.validTopSellerLabel[locale]) {
        validCount += 1;
      }
    });

    for (const locale of this.LOCALES) {
      if (this.initialCategoryLabel[locale] !== this.formData.editedCategoryLabel[locale]) {
        editedCount += 1;
      }
      if (this.initialTopSellerLabel[locale] !== this.formData.editedTopSellerLabel[locale]) {
        editedCount += 1;
      }
    }
    if (validCount === 3 && editedCount > 0 && !(this.initialCategoryLabel[this.LOCALES] || this.initialTopSellerLabel[this.LOCALES])){
      document.getElementById(`saveEditCategoryLabel_${this.modalID}`).removeAttribute('disabled');
    } else {
      document.getElementById(`saveEditCategoryLabel_${this.modalID}`).setAttribute('disabled', 'true');
    }
  }

  duplicateAll(event) {
    this.saveInitialData(event);
    this.copyCategoryLabel();
    this.copyTopSellerLabel();

    // validate if duplicate successful
    if (this.duplicated(this.currentLocale)) {
      popSnackbar('notice', I18n[window.currentLocale]['content_duplication_success_notice']);
    } else {
      popSnackbar('error', I18n[window.currentLocale]['content_duplication_success_error']);
    }
  }

  copyTopSellerLabel() {
    Object.keys(this.formData.editedTopSellerLabel).forEach(locale => {
      if (locale !== this.currentLocale) {
        this.formData.editedTopSellerLabel[locale] = this.formData.editedTopSellerLabel[this.currentLocale];
      }
    }, this);
  }

  copyCategoryLabel() {
    Object.keys(this.formData.editedCategoryLabel).forEach(locale => {
      if (locale !== this.currentLocale) {
        this.formData.editedCategoryLabel[locale] = this.formData.editedCategoryLabel[this.currentLocale];
      }
    }, this);
  }

  duplicated(currentLocale) {
    return Object.keys(this.formData.editedCategoryLabel).every((locale) => {
      return this.formData.editedCategoryLabel[locale] === this.formData.editedCategoryLabel[currentLocale];
    }, this);
  }

  saveCategoryLabel(event) {
    if (this.formData.editedCategoryLabel['en'] === '') {
      this.saveInitialData(event);
    }
    let taxonomyId = JSON.parse(event.currentTarget.dataset.object).id;
    let payload = {
      taxonomy: {
        label_one_text: this.formData.editedCategoryLabel,
        label_two_text: this.formData.editedTopSellerLabel
      }
    };
    editTaxonomyLabel(payload, taxonomyId);
  }
}
