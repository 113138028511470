import {Controller} from 'stimulus';
import {popSnackbar} from '../services/snackbar';
import {constructArrayParams} from '../services/construct_array_params';
import {message} from '../services/shared';
import {I18n} from '../translation';
import {emailValidation} from '../services/authentications/email_service';
import {setQuantity} from "../services/carts/update_quantity";
import {freeFieldValidation} from '../services/authentications/free_field_service';

export default class extends Controller {

  filterData = [];
  query = '';
  selectedIndex = -1;
  amount;

  connect() {
    if (this.element.dataset.type === 'digital') this.addEventListener();

    document.getElementById(`amount-${this.element.dataset.variantId}`).click();
  }

  filterCard(event) {
    if(event.target.checked) {
      this.filterData.push(event.target.value);
    } else {
      this.filterData.splice(this.filterData.indexOf(event.target.value), 1);
    }
    this.filterCards();
  }

  async filterCards() {
    const response = await fetch(`/search/filter-gift-card?q=${this.query}&${constructArrayParams(this.filterData, 'filter')}`);
    if (response.status === 200) {
      const text = await response.text();
      let vDom = document.createElement('div');
      vDom.innerHTML = text;
      document.getElementById('gift_cards_list').replaceWith(vDom.children[0]);
    } else {
      popSnackbar('error', 'Failed to filter');
    }
  }

  search(event){
    this.query = event.target.value;
    this.filterCards();
  }

  amountSelected(event) {
    const dataset = event.target.dataset;
    this.amount = dataset.amount;
    this.selectedIndex = dataset.id;
    this.resetSelectAmount(event);
    document.getElementById(`amount-${dataset.id}`).classList.add('sk-btn--secondary');
    if(!JSON.parse(event.target.dataset.type)){
      (+dataset.stock < 1) ? document.getElementById('add-to-cart-btn').setAttribute('disabled', true)
        : document.getElementById('add-to-cart-btn').removeAttribute('disabled');
    }
  }

  resetSelectAmount(event){
    const allAmount = JSON.parse(event.target.dataset.allData);
    allAmount.forEach((res) => {
      document.getElementById(`amount-${res.id}`).classList.remove('sk-btn--secondary');
    });
  }

  setGender(event){
      document.getElementById('gift_card_actual_gender').value = event.currentTarget.dataset.gender;
      document.getElementById('gift_card_gender').value = event.currentTarget.dataset.trGender;
  }
    fieldValidation(){
        let valid = []
        const domId = ['gift_card_gender','gift_card_first_name','gift_card_last_name']
        domId.forEach(id => {
            let dom = document.getElementById(id);
            valid.push(freeFieldValidation(dom,id+'_error_message'))
        });
        const allTrue = valid.every(element => element === true);
        return allTrue
    }

   addToCart(event) {
    const type = event.currentTarget.dataset.type;
    const receiver = type == 'digital_gift_card' && emailValidation(document.getElementById('receiver_email'), 'receiver-error');
    // const buyer = type == 'digital_gift_card' && emailValidation(document.getElementById('buyer_email'), 'buyer-error');
    if ((receiver && this.fieldValidation()  && !!this.amount?.length) || (type == 'physical_gift_card' && !!this.amount?.length)) {
      const form = document.getElementById('addCartForm');
      const formData = new FormData(form);
      formData.append('amount', this.amount);
      formData.append('variant_id', this.selectedIndex);
      formData.append('type', type);

        fetch('/carts', {
            method: 'PUT',
            headers: {
                'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
            },
            body: formData
        }).then((response) => response.json())
            .then((data) => {
                if (data.in_cart) {
                    message(I18n[window.currentLocale]['already_in_cart'], 'error');
                } else {
                    this.setQuantity('add', 1);
                    message(I18n[window.currentLocale]['product_added_cart'], 'success');
                }
            });
    } else {
      message(I18n[window.currentLocale]['fill_required_fields'], 'error');
    }
  }

  setQuantity(key, qty) {
    let count = parseInt(document.getElementById('cart_quantity').innerHTML) || parseInt(document.getElementById('cart_quantity_mobile').innerHTML);
    if (key === 'add') {
      document.getElementById('cart_quantity').innerHTML = count + qty;
      document.getElementById('cart_quantity_mobile').innerHTML = count + qty;
    } else {
      document.getElementById('cart_quantity_mobile').innerHTML = count - qty;
    }
  }

  addEventListener(){
    // [{id: 'receiver_email', error: 'receiver-error'}].forEach( value => {
      document.getElementById('receiver_email').addEventListener( 'input', () => {
        document.getElementById('receiver-error').innerText = '';
      });
    // });
  }
}
