import {Controller} from 'stimulus';

export default class extends Controller {
  basePolicy = document.getElementById('base-modal-cookie-policy');
  cookieParams = {
    enableGoogleAnalytics: false
  };

  rejectOrCloseBaseModal() {
    Object.keys(this.cookieParams).forEach((key) => {
      this.cookieParams[key] = false;
    });

    this.acceptCookies();
    this.basePolicy.remove();
  }

  enableGoogleAnalytics(e) {
    this.cookieParams[e.currentTarget.dataset.cookieParam] = !!e.currentTarget.checked;
  }

  acceptCookies() {
    fetch('/cookies/accept', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content
      },
      body: JSON.stringify({cookies: this.cookieParams})
    }).then(response => {
      if (response.ok) {
        this.basePolicy.remove();
        if(document.getElementById('preferencesModal')) document.getElementById('preferencesModal').click();
      } else {
        console.log('Error agreeing to cookie policy.');
      }
    });
  }

  acceptAllCookies() {
    Object.keys(this.cookieParams).forEach((key) => {
      this.cookieParams[key] = true;
    });

    this.acceptCookies();
  }

  updateCookieParams() {
    Object.keys(this.cookieParams).forEach((key) => {
      this.cookieParams[key] = !!this.getCookie(key) === true;
    });
  }

  getCookie(cname) {
    let name = cname + '=';
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  }
}